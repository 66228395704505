export default {
  cancelRequestCta: {
    id: 'dashboard_diagnosis_cancel_request',
    defaultMessage: 'Cancel request',
  },
  continueRequestCta: {
    id: 'dashboard_diagnosis_continue_request',
    defaultMessage: 'Continue creating request',
  },
  description: {
    id: 'dashboard_diagnosis_cancellation_modal_text',
    defaultMessage:
      'You’ll need to start over if you want to create a request later.',
  },
  title: {
    id: 'dashboard_diagnosis_cancellation_modal_title',
    defaultMessage: 'Cancel your request?',
  },
}
