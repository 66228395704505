<template>
  <div
    class="grid h-full w-full grow grid-cols-1 max-md:grid-flow-row md:grid-flow-col md:grid-cols-12"
    :class="
      !withMobileWebSidebar
        ? 'max-md:grid-rows-1'
        : 'max-md:grid-rows-[auto_1fr]'
    "
  >
    <div
      class="bg-surface-default-low md:bg-surface-default-hi md:col-span-5"
      :class="!withMobileWebSidebar ? 'max-md:hidden' : ''"
    >
      <aside
        class="flex flex-1 px-24 pt-8 md:justify-center md:pt-32 lg:justify-end lg:pl-0 lg:pr-96"
      >
        <div class="w-full md:max-w-380">
          <div class="flex flex-1 items-center justify-between py-4">
            <RevLink
              class="body-2 flex items-center space-x-4"
              :underlined="false"
              @click="back"
            >
              <IconArrowLeft />
              <span>{{ backLabel }}</span>
            </RevLink>

            <RevButtonIcon
              v-if="!disableCancelButton"
              :aria-label="i18n(translations.cancel)"
              class="block md:hidden"
              data-qa="cancel-request-icon"
              data-test="cancel-request-icon"
              :icon="IconCross"
              variant="secondary"
              @click="cancel"
            />
          </div>

          <div v-if="$slots.sidebar" class="md:pt-16">
            <slot name="sidebar" />
          </div>
        </div>
      </aside>
    </div>

    <div
      class="bg-surface-default-hi flex md:bg-surface-default-mid md:col-span-7"
    >
      <div class="flex flex-1 flex-col">
        <ProgressBar
          v-if="FF_ENABLE_CUSTOMER_CARE_PROGRESS_BAR && progressBarCurrentValue"
          :current-value="progressBarCurrentValue"
          data-qa="diagnosis-progress-bar"
          data-test="diagnosis-progress-bar"
        />

        <div class="relative flex-1">
          <div
            class="absolute inset-0 overflow-y-auto"
            @scroll="scrollContainer"
          >
            <div
              class="w-full px-24 pb-24 md:max-w-640 lg:mx-auto lg:pb-0 lg:pl-0 lg:pr-0"
            >
              <div
                v-if="!disableCancelButton"
                class="hidden w-full items-center justify-end py-4 pt-32"
                :class="!withMobileWebSidebar ? '' : 'md:flex'"
              >
                <RevButtonIcon
                  :aria-label="i18n(translations.cancel)"
                  class="hidden md:flex"
                  data-qa="cancel-request-icon"
                  data-test="cancel-request-icon"
                  :icon="IconCross"
                  variant="secondary"
                  @click="cancel"
                />
              </div>

              <div
                v-if="$slots.content"
                class="md:pt-16"
                :class="!withMobileWebSidebar ? 'pt-24' : 'pt-32'"
              >
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="$slots.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevLink } from '@ds/components/Link'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import ProgressBar from '../components/ProgressBar/ProgressBar.vue'

import translations from './MinimalWithSideBarLayout.translations'
import type { MinimalWithSideBarLayoutProps } from './MinimalWithSideBarLayout.types'

const props = withDefaults(defineProps<MinimalWithSideBarLayoutProps>(), {
  withMobileWebSidebar: true,
})

const emits = defineEmits(['back', 'cancel', 'scroll-child-element'])

const i18n = useI18n()

const { FF_ENABLE_CUSTOMER_CARE_PROGRESS_BAR } = useRuntimeConfig().public

const backLabel = props.ctaBackLabel ?? i18n(translations.back)

function back() {
  emits('back')
}

function cancel() {
  emits('cancel')
}

let lastScrollTop = 0
// SCROLL_THRESHOLD is somewhat arbitrary but is to prevent flickering
const SCROLL_THRESHOLD = 80

function scrollContainer(e: Event) {
  if (!window.matchMedia('(max-width: 767px)').matches) {
    return
  }
  const target = e.target as HTMLElement | null
  if (!target) return
  const { scrollTop } = target
  const scrollDifference = Math.abs(scrollTop - lastScrollTop)

  if (scrollDifference < SCROLL_THRESHOLD) {
    return
  }

  const direction = scrollTop > lastScrollTop ? 'down' : 'up'
  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

  emits('scroll-child-element', { direction })
}
</script>
