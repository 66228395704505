import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import type { Resolution } from '@backmarket/http-api/src/api-specs-resolution-engine/types/getResolution'

type FormatCustomerIssueParams =
  | IssueSibling[]
  | Resolution['problemContext']['problem']['issues']

export const formatCustomerIssue = (issueList: FormatCustomerIssueParams) => {
  return issueList.map((issue) => {
    if (issue.tag) {
      return `${issue.tag}-${issue.customerIssue}`
    }

    return issue.customerIssue
  })
}
