import { HELP_CENTER_ERROR_TYPE } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/error'

/**
 * Helper function to check if the error type received is equal
 * to invalid problem
 */

interface errorEvent {
  type: string
  data: unknown
}

export const isInvalidProblemErrorType = (
  _context: unknown,
  event: errorEvent,
) => {
  if (typeof event.data === 'object') {
    return (
      (event.data as { type: string }).type ===
      HELP_CENTER_ERROR_TYPE.invalidProblem
    )
  }

  return false
}
