export type MultipleCondType = {
  type: string
  guards: string[]
}

/**
 * Helper function to add AND statement in XState
 */

export const every = (...guards: string[]): MultipleCondType => {
  return {
    type: 'every',
    guards,
  }
}

/**
 * Helper function to add OR statement in XState
 */

export const some = (...guards: string[]): MultipleCondType => {
  return {
    type: 'some',
    guards,
  }
}

/**
 * Helper function to add NOT statement in XState
 */

export const none = (...guards: string[]): MultipleCondType => {
  return {
    type: 'none',
    guards,
  }
}
