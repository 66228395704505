<template>
  <RevDialog
    :name="CUSTOMER_CARE_MODALS_NAMES.cancellationModal"
    :title="i18n(translations.title)"
  >
    <template #body>
      <p class="body-1">{{ i18n(translations.description) }}</p>
    </template>

    <template #footer="{ close }">
      <RevButton
        class="my-12"
        data-qa="cancel-request"
        full-width="always"
        :to="
          resolveLocalizedRoute({
            name: DASHBOARD.MY_ORDERS.SELF,
          })
        "
        variant="primary"
      >
        {{ i18n(translations.cancelRequestCta) }}
      </RevButton>

      <RevButton
        data-qa="continue-request"
        full-width="always"
        type="button"
        variant="secondary"
        @click="close"
      >
        {{ i18n(translations.continueRequestCta) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import { CUSTOMER_CARE_MODALS_NAMES } from '../names'

import translations from './CancellationModal.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
</script>
