export default {
  description: {
    id: 'dashboard_diagnosis_option_refund_description',
    defaultMessage:
      'Return your item to the refurbisher for free. Once they receive it, you will be refunded within 2 business days.',
  },
  kicker: {
    id: 'dashboard_diagnosis_refund_timeline',
    defaultMessage: '5 to 6 days',
  },
  title: {
    id: 'dashboard_diagnosis_option_refund',
    defaultMessage: 'Get a refund',
  },
}
