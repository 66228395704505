export default {
  backToOrdersCta: {
    id: 'dashboard_diagnosis_fail_loading_back_to_orders_cta',
    defaultMessage: 'Orders',
  },
  tryAgainCta: {
    id: 'dashboard_diagnosis_fail_loading_retry',
    defaultMessage: 'Try again',
  },
  title: {
    id: 'customer_care_fail_loading_screen_title',
    defaultMessage: 'Something went wrong',
  },
  subtitle: {
    id: 'customer_care_fail_loading_screen_subtitle',
    defaultMessage: 'Please give us a sec and then give it another go.',
  },
}
