import type { LinkInternal } from '../../../api-models'
import type { Url } from '../../../standards/Url'

export const ISSUE_ACTION_TARGET = {
  bmWarrantyClaim: 'BM_WARRANTY_CLAIM',
  getResolutionOptions: 'GET_RESOLUTION_OPTIONS',
  getResolutionOptionsNoWarrantyExclusion:
    'GET_RESOLUTION_OPTIONS_NO_WARRANTY_EXCLUSION',
  showImei: 'SHOW_IMEI',
  imeiSelfcareContent: 'IMEI_SELFCARE_CONTENT',
  insuranceCancellation: 'INSURANCE_CANCELLATION',
  insurancePayment: 'INSURANCE_PAYMENT',
  myOrders: 'MY_ORDERS',
  mySales: 'MY_SALES',
  orderlineLifecycle: 'ORDERLINE_LIFECYCLE',
  remoteAssistance: 'REMOTE_ASSISTANCE',
  insuranceRequest: 'INSURANCE_REQUEST',
  invoiceRequestOrderlineCanceled: 'INVOICE_REQUEST_ORDERLINE_CANCELED',
  invoiceRequestOrderlineNotShipped: 'INVOICE_REQUEST_ORDERLINE_NOT_SHIPPED',
  invoiceRequestPending: 'INVOICE_REQUEST_PENDING',
} as const

export type IssueActionTarget =
  (typeof ISSUE_ACTION_TARGET)[keyof typeof ISSUE_ACTION_TARGET]

export const ISSUE_ACTION_TRIGGER_PROCESS_TARGET = {
  invoiceRequest: 'INVOICE_REQUEST',
} as const

export type IssueActionTriggerProcessTarget =
  (typeof ISSUE_ACTION_TRIGGER_PROCESS_TARGET)[keyof typeof ISSUE_ACTION_TRIGGER_PROCESS_TARGET]

export type IssueActionExternalRedirectTarget = Url
export type IssueActionRedirectToPartnerPortalTarget = Url

export const ISSUE_ACTION_TYPE = {
  insurerPortal: 'INSURER_PORTAL',
  internalRedirect: 'INTERNAL_REDIRECT',
  externalRedirect: 'EXTERNAL_REDIRECT',
  triggerProcess: 'TRIGGER_PROCESS',
  redirectToPartnerPortal: 'REDIRECT_TO_PARTNER_PORTAL',
} as const

export type IssueActionType =
  | (typeof ISSUE_ACTION_TYPE)[keyof typeof ISSUE_ACTION_TYPE]
  | unknown

export type MobilePlanActionDetails = {
  partner_provider_label: string
}

export type ShowImeiActionDetails = {
  imei: string
}

export type IssueAction = {
  type: IssueActionType
  target:
    | IssueActionExternalRedirectTarget
    | IssueActionTarget
    | IssueActionExternalRedirectTarget
  details:
    | {
        [key: string]: string
      }
    | LinkInternal
    | MobilePlanActionDetails
    | ShowImeiActionDetails
}

export const ISSUE_TAG = {
  accessories: 'ACCESSORIES',
  badProduct: 'BAD_PRODUCT',
  changeMind: 'CHANGE_MIND',
  delivery: 'DELIVERY',
  esthetical: 'ESTHETICAL',
  imei: 'IMEI',
  insurance: 'INSURANCE',
  invoice: 'INVOICE',
  other: 'OTHER',
  payment: 'PAYMENT',
  productInformationRequest: 'PRODUCT_INFORMATION_REQUEST',
  swap: 'SWAP',
  technical: 'TECHNICAL',
  withdrawal: 'WITHDRAWAL',
} as const

export type IssueTag = (typeof ISSUE_TAG)[keyof typeof ISSUE_TAG]
