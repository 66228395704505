<template>
  <RevContainer class="mb-72 mt-56 md:mt-72">
    <RevCard class="p-24">
      <RevContentBlock
        :button-label="i18n(translations.cta)"
        :has-rounded-image="true"
        :image-props="{
          alt: 'illustration-invalid-problem-error-screen',
          height: 222,
          width: 448,
          src: '/img/customer-care/FastDelivery.svg',
        }"
        :title="i18n(translations.title)"
        title-tag="h2"
        title-variant="title2"
        :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
      >
        <template #default>
          {{ i18n(translations.subtitle) }}
        </template>
      </RevContentBlock>
    </RevCard>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './InvalidProblemErrorScreen.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
</script>
