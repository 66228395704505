<template>
  <RevCard
    class="flex items-center rounded-none py-20 shadow-none md:shadow-short md:rounded-lg md:px-24"
    :class="!scrollState.isScrolledDown ? 'space-x-16' : ''"
  >
    <div :class="{ hidden: scrollState.isScrolledDown }">
      <RevIllustration alt="" :height="40" :src="productImage" :width="40" />
    </div>
    <div class="flex flex-1 flex-col text-left">
      <p class="body-2-bold">
        {{ productTitle }}
      </p>

      <p class="caption" :class="{ hidden: scrollState.isScrolledDown }">
        {{ i18n(translations.orderNumber, { orderId }) }}
      </p>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import { useScrollState } from '~/scopes/customer-care/common/composables/useScrollState'

import translations from './OrderlineRecapBlock.translations'
import type { OrderlineRecapBlockProps } from './OrderlineRecapBlock.types'

defineProps<OrderlineRecapBlockProps>()

const i18n = useI18n()
const { scrollState } = useScrollState()
</script>
