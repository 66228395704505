import type { OrderlineDetailed } from '../../api-specs-my-orders-api/types/orderlineDetailsResponse'
import { createHttpEndpoint } from '../../utils'

import type { IssueListResponse } from './types/issueListResponse'
import type { ProblemResponse } from './types/problemResponse'

export interface GetActionQueryParams {
  orderlineId: OrderlineDetailed['orderlineId']
  customerIssues: string
}
export interface GetIssuesQueryParams {
  orderlineId: OrderlineDetailed['orderlineId']
  customerIssues?: string
}

/**
 * Get the list of issues at a specific stage of the diagnosis
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/help-center-diagnosis-api/definition#tag/diagnosis/operation/get-graph-nodes}
 */
export const getIssues = createHttpEndpoint<
  IssueListResponse,
  GetIssuesQueryParams
>({
  method: 'GET',
  operationId: 'fetchIssues',
  path: '/help-center/api/v1/auth/diagnosis/graph/nodes',
})

/**
 * Get the problem
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/help-center-diagnosis-api/definition#tag/diagnosis/operation/get-problem}
 */
export const getProblem = createHttpEndpoint<
  ProblemResponse,
  GetActionQueryParams
>({
  method: 'GET',
  operationId: 'getCustomerIssuesAction',
  path: '/help-center/api/v1/auth/diagnosis/problem',
})
