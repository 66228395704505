export default {
  description: {
    id: 'resolution_flow_resolution_option_straight_refund_description',
    defaultMessage:
      'Receive a refund to your original payment method within 3 business days.',
  },
  kicker: {
    id: 'resolution_flow_resolution_option_straight_refund_timeline',
    defaultMessage: '5 to 6 days',
  },
  title: {
    id: 'resolution_flow_resolution_option_straight_refund',
    defaultMessage: 'Get a refund',
  },
}
