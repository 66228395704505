import { type SalesCustomerCareScope } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export interface IssuesCommunication {
  selectedIssues: string[]
  tags: string[]
}

interface encodeIssuesParams {
  issues: IssuesCommunication
  scope: SalesCustomerCareScope
}

interface decodeIssuesParams {
  issuesBase64String: string
  scope: SalesCustomerCareScope
}

export function encodeIssues({ issues, scope }: encodeIssuesParams) {
  const { logError } = useSalesCustomerCareLogger()

  try {
    const json = JSON.stringify(issues)
    const base64 = btoa(json)

    return base64
  } catch (error) {
    logError({
      errorTitle: `${scope} Error occured when encoding issues`,
      attributes: {
        error: error as Error,
        issues,
      },
    })
    throw error
  }
}

export function decodeIssues({
  issuesBase64String,
  scope,
}: decodeIssuesParams) {
  const { logError } = useSalesCustomerCareLogger()

  try {
    const rawString = atob(issuesBase64String)
    const json: IssuesCommunication = JSON.parse(rawString)

    return json
  } catch (error) {
    logError({
      errorTitle: `${scope} Error occured when decoding issues`,
      attributes: {
        error: error as Error,
        issues: issuesBase64String,
      },
    })
    throw error
  }
}
