export default {
  back: {
    id: 'resolution_flow_action_bar_navigation_back',
    defaultMessage: 'Back',
  },
  cancel: {
    id: 'dashboard_diagnosis_cancel',
    defaultMessage: 'Cancel',
  },
}
