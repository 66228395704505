export default {
  cta: {
    id: 'diagnosis_common_invalid_problem_context_error_back_to_my_orders',
    defaultMessage: 'Back to My Orders',
  },
  title: {
    id: 'diagnosis_common_invalid_problem_context_error_heading',
    defaultMessage: 'Something about your order just changed',
  },
  subtitle: {
    id: 'diagnosis_common_invalid_problem_context_error_text',
    defaultMessage:
      'Please go back to “My orders” and check your order status. If you still need help, click “Ask for help” again.',
  },
}
