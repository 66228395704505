<template>
  <RevContainer class="mb-72">
    <RevBreadcrumb
      ariaLabel="diagnosis-fail-loading-screen-navigation"
      :breads
      class="mb-32 md:mb-24"
    />
    <RevCard class="p-24">
      <RevContentBlock
        :button-label="i18n(translations.tryAgainCta)"
        :has-rounded-image="true"
        :image-props="{
          alt: 'illustration-invalid-problem-error-screen',
          height: 222,
          width: 448,
          src: '/img/common/not-found-block.svg',
        }"
        :title="i18n(translations.title)"
        title-tag="h2"
        title-variant="title2"
        @click="onRetry"
      >
        <template #default>
          {{ i18n(translations.subtitle) }}
        </template>
      </RevContentBlock>
    </RevCard>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './FailLoadingScreen.translations'

const emit = defineEmits(['retry'])

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const breads = [
  {
    link: resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF }),
    title: i18n(translations.backToOrdersCta),
  },
  {
    link: {},
    title: i18n(translations.title),
  },
]

function onRetry() {
  emit('retry')
}
</script>
